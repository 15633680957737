import * as Fhir from "../FhirModules/Fhir";
import {NitTools} from "../NursitTools";
import * as environment from "../../../../config/environment.json";
import {PatientItem} from "./PatientItem";
import { ConfigService } from "resources/services/ConfigService";

export class PatientMark {
    public index: number;
    private __cssClass: string = '';
    public patient: PatientItem;

    public get cssClass(): string {
        return this.__cssClass;
    };

    private __checked: boolean = false;

    public get checked(): boolean {
        if (typeof this.__checked === "undefined")
            if ((this.index === 10 && ConfigService.UseAssessmentForSignal10) || (this.index === 5 && ConfigService.UseBarthelIndexForFlag5)) this.__checked = true;

        return this.__checked;
    }

    public set checked(value: boolean) {
        if (value === this.__checked) return;

        // 5 and 10 always have to be checked to be displayed correctly
        if ((this.index === 10 && ConfigService.UseAssessmentForSignal10) || (this.index === 5 && ConfigService.UseBarthelIndexForFlag5))
            value = true;

        this.__checked = value;

        // if this is iso and iso is not active, no other prop can be true
        if ((!ConfigService.UseIsolationForFlag1 && this.index === 1)
            || (!ConfigService.UseAssessmentForSignal10 && this.index === 10)
            || (!ConfigService.UseBarthelIndexForFlag5 && this.index === 5)
         ) {
            this.yellow = false;
            this.red = false;
        }

        this.updatePatientMark(value);
        this.updateCssClass();
    }

    private __red: boolean = false;

    public get red(): boolean {
        return this.__red;
    }

    private validateValue(value : boolean) {
        if (   (this.index === 10 && !ConfigService.UseAssessmentForSignal10) 
            || (this.index === 5 && !ConfigService.UseBarthelIndexForFlag5)
            || (this.index === 1 && !ConfigService.UseIsolationForFlag1)
        ){
            value = false;
        }

        return value;
    }

    public set red(value: boolean) {
        value = this.validateValue(value);

        if (value === this.__red) 
            return;

        this.__red = value;
        this.updatePatientMark(value, 'red');
        this.updateCssClass();

        if (this.index === 10 && !ConfigService.UseAssessmentForSignal10) {
            console.warn("RED?!");
        }
    }

    private __yellow: boolean;

    public get yellow(): boolean {
        return this.__yellow;
    }

    public set yellow(value: boolean) {
        value = this.validateValue(value);
        if (value === this.__yellow) return;
        this.__yellow = value;
        this.updatePatientMark(value, 'yellow');
        this.updateCssClass();
    }

    private updatePatientMark(value: boolean, CheckedRedYellow?: string) {
        if (CheckedRedYellow) {
            if (CheckedRedYellow === "checked") CheckedRedYellow = undefined;
            else if (CheckedRedYellow !== "red" && CheckedRedYellow !== "yellow") {
                console.warn('Invalid PropertyName: "' + CheckedRedYellow + '" for Marker');
            }

            return;
        }

        if (this.patient) {
            let markerId = String(this.index);
            if (CheckedRedYellow) markerId += "_" + CheckedRedYellow;

            if (!CheckedRedYellow) CheckedRedYellow = "checked";
            this[CheckedRedYellow] = value;

            const flag = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${markerId}`, true);
            if (flag)
                flag.code = value ? 'true' : 'false';
        }
    }

    public overrideColor : string;

    public updateCssClass() {
        if ((this.index === 5 && ConfigService.UseBarthelIndexForFlag5) && !this.red && !this.yellow && this.checked) {
            this.__cssClass = 'active-green';
        } else {
            if (this.red) this.__cssClass = 'active-red';
            else if (this.yellow) this.__cssClass = 'active-yellow';
            else if (this.checked) this.__cssClass = 'active';
            else this.__cssClass = '';
        }
    }

    constructor(data?: any) {
        if (data) Object.assign(this, data);
    }
}
