import {NitTools} from "./NursitTools";
import {PatientItem} from "./Patient/PatientItem";
import {FhirService} from "../services/FhirService";
import {Tools} from "./FhirModules/Fhir";
import {I18N} from "aurelia-i18n";

export class Allergy {
    private static __cleanAllergyText(i18n : I18N, s : string, repl? : string) : string {
        if (!s) return '';

        const allergyTxt = i18n.tr('allergy').toUpperCase();
        const intoleranceTxt = i18n.tr('intolerance').toUpperCase();

        let result = s.trim();
        if (result.toUpperCase().startsWith(allergyTxt))
            result = result.substring(allergyTxt.length).trim();

        if (result.toUpperCase().startsWith(intoleranceTxt))
            result = result.substring(intoleranceTxt.length).trim();

        if (repl && result.toUpperCase().startsWith(repl))
            result = result.substring(repl.length).trim();

        if (result.startsWith('-'))
            result = result.substring(1).trim();

        return result;
    }

    private static __sortResult(result : IAllergy[]) {
        result.sort((b,a) => {
            let s1 = String(a.text);
            let s2 = String(b.text);

            return s2.localeCompare(s1);
        });
    }

    public static FromIntolerances(allergyIntolerances : fhir4.AllergyIntolerance [], i18n : I18N) : IAllergy[] {
        const result : IAllergy[] = [];
        if (NitTools.IsArray(allergyIntolerances)) {
            for (const a of allergyIntolerances) {
                result.push(this.FromIntolerance(a, i18n));
            }
        }

        this.__sortResult(result);

        return result;
    }

    public static FromIntolerance(allergyIntolerance : fhir4.AllergyIntolerance, i18n : I18N) : IAllergy {
        let txt = allergyIntolerance.code?.text ? Allergy.__cleanAllergyText(i18n, allergyIntolerance.code.text, i18n.tr(allergyIntolerance.type)) : i18n.tr('allergy_without_text');
        let status : string = '';

        if (typeof allergyIntolerance.clinicalStatus === "object") {  // R4
            if (allergyIntolerance.clinicalStatus["text"]) {
                status = allergyIntolerance.clinicalStatus["text"];
            } else if (NitTools.IsArray(allergyIntolerance.clinicalStatus?.coding)) { // when no text is provided as draeger does:
                const arr = [];
                for (const code of allergyIntolerance.clinicalStatus?.coding.filter(o=>o.code).map(o=>o.code)) {
                    try {
                        let tmp = i18n.tr(code);
                        if (tmp) {
                            let s0 = tmp[0].toUpperCase();
                            tmp = `${s0}${tmp.substring(1)}`;
                            arr.push(tmp);
                        }
                    }
                    catch (e) {
                        arr.push(i18n.tr(code));
                        console.warn(e);
                    }
                }

                status = arr.join(', ');
            }
        } else if (typeof allergyIntolerance.clinicalStatus === "string") // R3
            status = i18n.tr(allergyIntolerance.clinicalStatus);

        let verificationStatus = "";
        if (typeof allergyIntolerance.verificationStatus === "string")
            verificationStatus = i18n.tr(allergyIntolerance.verificationStatus);
        else if (typeof allergyIntolerance.verificationStatus === "object") {
            if (allergyIntolerance.verificationStatus["text"])
                verificationStatus = i18n.tr(allergyIntolerance.verificationStatus["text"]);
        }

        let isActive : boolean = true;
        if (typeof allergyIntolerance.clinicalStatus === 'object' && allergyIntolerance.clinicalStatus.coding) {
            // R4
            isActive = !allergyIntolerance.clinicalStatus.coding.find(o => o.code === "inactive" || o.code === "resolved");
        } else  if (typeof allergyIntolerance.clinicalStatus === 'string') {
            // R3
            isActive = ["inactive","resolved"].indexOf(allergyIntolerance.clinicalStatus) === -1;
        }

        const result : IAllergy = {
            id: allergyIntolerance.id,
            code: allergyIntolerance.code?.coding?.[0]?.code,
            text: txt,
            isActive : isActive,
            type:  i18n.tr(allergyIntolerance.type || "allergy_no_type"),
            fhirType: allergyIntolerance.type||'',
            verificationStatus: verificationStatus,
            status: status||i18n.tr('unknown'),
            criticality: allergyIntolerance.criticality||'',
            category: NitTools.IsArray(allergyIntolerance.category)? i18n.tr(String(allergyIntolerance.category[0])):'',
            assertedDate: allergyIntolerance["assertedDate"]||allergyIntolerance.recordedDate,
            intoleranceResource: allergyIntolerance
        };

        const _crit = allergyIntolerance._criticality?.extension?.find(e => e.url.endsWith('DetailedCriticality') && (e.valueString || e.valueCoding));

        if (_crit) {
            if (_crit.valueString)
                result.criticality = i18n.tr(_crit.valueString);
            else if (_crit.valueCoding) {
                if (_crit.valueCoding.display) {
                    result.criticality = _crit.valueCoding.display;
                } else {
                    result.criticality = i18n.tr(_crit.valueCoding.code);
                }
            }
        }

        if (allergyIntolerance.reaction?.length > 0) {
            const reaction = allergyIntolerance.reaction[0];
            if (reaction) {
                result.reactionSeverity = reaction.severity;

                const trId = `allergy_reaction_severity_${reaction.severity}`;
                const trSeverity = i18n.tr(trId);
                if (trId != trSeverity)
                    result.reactionSeverity = trSeverity;

                if (reaction.description) {
                    // try to get the reaction from description
                    result.reaction = reaction.description;
                } else {
                    // not set, so try to gather them from manifestation
                    const reactions = [];
                    for (const manifestation of reaction.manifestation) {
                        if (manifestation.text) {
                            reactions.push(manifestation.text);
                        } else {
                            for (const coding of manifestation.coding) {
                                reactions.push(coding.display || coding.code);
                            }
                        }
                    }

                    result.reaction = reactions.join(', ');
                }
            }
        }

        return result;
    }

    public static FromAnswer(answer, i18n: I18N) : IAllergy {
        if (!answer?.extension) return undefined;
        const result : IAllergy = {
            id: answer.id,
            code: Tools.GetExtensionValueFromUrl(answer, '/allergy-code'),
            text: Tools.GetExtensionValueFromUrl(answer, '/allergy-display'),
            criticality: Tools.GetExtensionValueFromUrl(answer, '/allergy-criticality'),
            category: i18n.tr(Tools.GetExtensionValueFromUrl(answer, '/allergy-category')),
            status: Tools.GetExtensionValueFromUrl(answer, '/allergy-status'),
            type: Tools.GetExtensionValueFromUrl(answer, '/allergy-type'),
            verificationStatus: Tools.GetExtensionValueFromUrl(answer, '/allergy-verification'),
            assertedDate: Tools.GetExtensionValueFromUrl(answer, '/allergy-recorded'),
            isActive: NitTools.ParseBool(Tools.GetExtensionValueFromUrl(answer, '/allergy-is-active')),
            reaction: Tools.GetExtensionValueFromUrl(answer, '/allergy-reaction'),
            reactionSeverity: Tools.GetExtensionValueFromUrl(answer, '/allergy-reaction-severity')
        };

        return result;
    }

    public static FromAnswers(answers : any[], i18n:I18N) : IAllergy[] {
        const result = [];
        for (const a of answers)
        {
            const itm = this.FromAnswer(a, i18n);
            if (itm) result.push(itm);
        }

        this.__sortResult(result);

        return result;
    }

    public static async LoadForPatient(patient : PatientItem, fhirService : FhirService, i18n : I18N) {
        const url = `AllergyIntolerance?patient=${patient?.id}`;
        const allergyIntolerances = await fhirService.fetch(url, true, undefined, true);
        patient.allergies = this.FromIntolerances(allergyIntolerances, i18n);
    }
}

export interface IAllergy {
    id: string;
    code : string;
    text: string;
    type?: string;
    category?: string;
    verificationStatus?: string;
    status?: string;
    assertedDate? : string;
    criticality? : string;
    isActive : boolean;
    fhirType? : string;
    intoleranceResource?;
    reaction?:string;
    reactionSeverity?:string;
}
