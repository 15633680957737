export type RBACPathMap<T> = {
    [P in keyof T]: T[P] extends object ? RBACPathMap<T[P]> : string;
};

export type RBACApplication = {
    _state: {
        title: string;
        type: "application";
    };
} & {
    [key: string]: RBACModule | RBACFeature;
};

export type RBACModule = {
    _state?: {
        title: string;
        type: "module";
    };
} & {
    [key: string]: RBACModule | RBACFeature;
};

export type RBACFeature = {
    _state: {
        title: string;
        type: "feature";
        parameters?: { [key: string]: RBACParameter };
    };
    _path?: string;
};

type RBACParameter = {
    title: string;
    type: "coding" | "canonical";
};

function validateRBACFeature(feature: RBACFeature, path = "") {
    const errors = [];
    if (typeof feature !== "object" || feature === null || Array.isArray(feature)) {
        errors.push(`${path} is not an object`);
        return errors;
    }

    const state = feature._state;
    if (!state || typeof state !== "object" || state.type !== "feature" || typeof state.title !== "string") {
        errors.push(`${feature._path}._state is invalid for a feature`);
    }

    if (state && state.parameters) {
        Object.keys(state.parameters).forEach((key) => {
            const param = state.parameters[key];
            if (!["coding", "canonical"].includes(param.type)) {
                errors.push(`${feature._path}._state.parameters.${key}.type is not 'coding' or 'canonical'`);
            }
        });
    }

    Object.keys(feature).forEach((key) => {
        if (key !== "_state" && key !== "_path") {
            errors.push(`${feature._path}.${key} shouldn't exist in feature`);
        }
    });

    return errors;
}

function validateRBACModule(module: RBACModule, path = "") {
    const errors = [];
    if (typeof module !== "object" || module === null || Array.isArray(module)) {
        errors.push(`${path} is not an object`);
        return errors;
    }

    const state = module._state;
    if (state) {
        if (typeof state !== "object" || state.type !== "module" || typeof state.title !== "string") {
            errors.push(`${module._path}._state is invalid for a module`);
        }
    }

    Object.keys(module).forEach((key) => {
        if (key !== "_state" && key !== "_path") {
            // @ts-ignore
            const value = module[key];
            const newPath = `${path}.${key}`;
            if (value && typeof value === "object" && value._state) {
                if (value._state.type === "module") {
                    errors.push(...validateRBACModule(value as RBACModule, newPath));
                } else if (value._state.type === "feature") {
                    errors.push(...validateRBACFeature(value as RBACFeature, newPath));
                }
            } else {
                errors.push(`${newPath} is neither a module nor a feature`);
            }
        }
    });

    return errors;
}

function validateRBACApplication(app: RBACApplication) {
    const errors = [];
    if (typeof app !== "object" || app === null || Array.isArray(app)) {
        errors.push("Application is not a valid object");
        return errors;
    }

    const state = app._state;
    if (!state || typeof state !== "object" || state.type !== "application" || typeof state.title !== "string") {
        errors.push(`${app._path}._state is invalid for an application`);
    }

    Object.keys(app).forEach((key) => {
        if (key !== "_state" && key !== "_path") {
            const value = app[key];
            const newPath = `Application.${key}`;
            if (value && typeof value === "object" && value._state) {
                if (value._state.type === "module") {
                    errors.push(...validateRBACModule(value as RBACModule, newPath));
                } else if (value._state.type === "feature") {
                    errors.push(...validateRBACFeature(value as RBACFeature, newPath));
                }
            } else {
                errors.push(`${newPath} is neither a module nor a feature`);
            }
        }
    });

    return errors;
}

function checkStructure(obj: any) {
    return validateRBACApplication(obj[Object.keys(obj)[0]]);
}

function createFeatures<T>(obj: T, parentKey: string[] = []): RBACPathMap<T> {
    const result: any = {};

    if (
        // @ts-ignore
        obj._state?.type === "feature" &&
        !Object.prototype.hasOwnProperty.call(obj, "_path")
    ) {
        // @ts-ignore
        obj._path = null;
    }

    Object.keys(obj).forEach((key) => {
        const fullPath = [...parentKey, key];
        // @ts-ignore
        const value = obj[key];

        if (key === "_state") {
            result[key] = value;
        } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            result[key] = createFeatures(value, fullPath);
            result[key]._path = fullPath.slice(0, fullPath.length).join(".");
        }
    });
    return result;
}

export const FEATURES = createFeatures({
    CAREIT: {
        _state: {
            title: "CareIt",
            type: "application",
        },
        CAREPLAN: {
            _state: {
                title: "Pflegeplan",
                type: "module",
            },
            VIEW: {
                _state: {
                    title: "Pflegeplan anzeigen",
                    type: "feature",
                },
            } as RBACFeature,
            PROCEDURE: {
                _state: {
                    title: "Maßnahmen",
                    type: "module",
                },
                ADD: {
                    _state: {
                        title: "Maßnahmen hinzufügen",
                        type: "feature",
                        parameters: {
                            GMT: {
                                title: "GMT",
                                type: "coding",
                            },
                        },
                    },
                } as RBACFeature,
            },
            ADHOC: {
                _state: {
                    title: "Adhoc Maßnahme",
                    type: "feature",
                },
            } as RBACFeature,
            ORDER: {
                _state: {
                    title: "Anordnung",
                    type: "feature",
                },
            } as RBACFeature,
        },
        PLANNING: {
            _state: {
                title: "Planung",
                type: "module",
            },
            VIEW: {
                _state: {
                    title: "Planung anzeigen",
                    type: "feature",
                },
            } as RBACFeature,
            ADD: {
                _state: {
                    title: "Planung hinzufügen",
                    type: "feature",
                },
            } as RBACFeature,
            SAVE: {
                _state: {
                    title: "Planung speichern",
                    type: "feature",
                },
            } as RBACFeature,
            CONFIRM: {
                _state: {
                    title: "Planung freigeben",
                    type: "feature",
                },
            } as RBACFeature,
            DIAGNOSIS: {
                _state: {
                    title: "Alle Diagnose",
                    type: "feature",
                },
            } as RBACFeature,
            TEMPLATES: {
              _state: {
                title: "Vorlagen",
                type: "module",
              },
              VIEW: {
                _state: {
                  title: "Vorlagen anzeigen",
                  type: "feature",
                },
              } as RBACFeature,
              SAVE: {
                _state: {
                  title: "Vorlagen speichern",
                  type: "feature",
                },
              } as RBACFeature,
              USE: {
                _state: {
                  title: "Vorlagen benutzen",
                  type: "feature",
                },
              } as RBACFeature,
            }
        },
        NAVIGATION: {
            _state: {
                title: "Navigation",
                type: "feature",
                parameters: {
                    ROUTES: {
                        title: "Routes",
                        type: "coding",
                    },
                },
            },
        } as RBACFeature,
        FORMS: {
            _state: {
                title: "Forms",
                type: "feature",
                parameters: {
                    QUESTIONNAIRES: {
                        title: "Questionnaires",
                        type: "canonical",
                    },
                },
            },
        } as RBACFeature,
    },
} as const);

const isValid = checkStructure(FEATURES);

if (isValid.length > 0) {
    isValid.forEach((error) => console.error("RBAC Structure Error:", error));
}
