const moment = require("moment");

export class Encounter {
    public static UseEncounterEndFilter: boolean = false;

    public static IsEncounterInCurrentPeriod(encounter): boolean {
        if (!encounter) return false;
        if (!Encounter.UseEncounterEndFilter) return true;
        let curDate = new Date();
        if (!encounter.period) return true;
        else {
            if (!encounter.period.end) return true;
            else {
                if (moment(encounter.period.end).isSameOrAfter(curDate)) {
                    return true;
                } else {
                    console.debug("Encounter with encounter period.end before now ignored: ", encounter);
                    return false;
                }
            }
        }
    }
}
