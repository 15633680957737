import * as environment from "../../../config/environment.json";
import {ConfigService} from "../services/ConfigService";

export class EmployeeInfo {
    familyName: string;
    givenName: string;
    userId: string;
}

export class RuntimeInfo {
    public static ConsoleEnabled: boolean = true;
    public static Performance = {
        getStart: function (lbl?: string) {
            let result = -1;
            if (performance && performance.now) {
                result = performance.now();
            }

            //this.log(`Returned starting ms ${result}`);

            return result;
        },
        log: function (txt: string) {
            if (this.ConsoleEnabled)
                console.debug(`[PERFORMANCE]: ${txt}`);
        },
        measure: function (startMs: number, lbl?: string) {
            if (performance && performance.now) {
                if (!lbl) lbl = "Taks";
                const delta = performance.now() - startMs;
                if (delta > 1000) {
                    const s = (delta / 1000).toFixed(2);
                    this.log(`"${lbl}" took ${s}s to complete`);
                } else {
                    this.log(`"${lbl}" took ${delta}ms to complete`);
                }
            }
        }
    };

    private static _isLoading: boolean = false;

    public static get IsLoading(): boolean {
        return this._isLoading;
    }

    public static set IsLoading(value: boolean) {
        this._isLoading = value;
        if (!value) this.MessageText = undefined;
    }

    private static _messageText: string;

    public static set MessageText(value: string) {
        this._messageText = value ? String(value).trim() : undefined;
        //if (ConfigService.Debug) console.debug("Updated loading Message to: " + this._messageText);
    }

    public static get MessageText(): string {
        return this._messageText;
    }

    public static CurrentWardId: string;
    private static infoTimeOutHandle = undefined;
    public static PrintFrame: HTMLDivElement;
    public static PrintDialog: HTMLDivElement;
    public static SelectedEncounter: any;
    public static PkmsRelevanceStart: number;
    public static get AllowReleasePatient() : boolean {
        return ConfigService.cfg.features["allowReleasePatient"];
    }

    public static ShowInfo(message: string, autoHide: boolean = true, onclick: Function = undefined) {
        if (this.infoTimeOutHandle) {
            window.clearTimeout(this.infoTimeOutHandle);
            this.infoTimeOutHandle = undefined;
        }

        let $popup = $('.popup-info');
        $popup.find(".popup-info-text").html(message);
        $popup.find("clickable").off('click');

        if (autoHide) {
            this.infoTimeOutHandle = window.setTimeout(() => {
                if (!$popup.is(":hidden"))
                    $popup.fadeToggle();
            }, 2000);
        }

        if ($popup.is(":hidden")) {
            $popup.fadeToggle();
        }

        if (typeof onclick === "function") {
            $popup.find("clickable").on("click", () => onclick());
        }
    }

    public static HideInfo() {
        let $popup = $('.popup-info');
        $popup.hide();
    }

    private static __embedded: boolean = undefined;

    public static get Embedded(): boolean {
        if (typeof this.__embedded === "undefined") {
            this.__embedded = window.location.href.indexOf('embedded=1') > -1;
            this.CheckEmbeddedClass();
        }

        return this.__embedded;
    }

    private static CheckEmbeddedClass() {
        if (this.Embedded) {
            document.body.classList.add("embedded");
        } else {
            document.body.classList.remove("embedded");
        }
    }

    public static set Embedded(isEmbedded: boolean) {
        this.__embedded = isEmbedded;
        this.CheckEmbeddedClass();
    }

    public static DataProxy = {
        enabled: false,
        source: "http://207.180.250.172:8500",
        isPrincipa: false,
        pumpUrls: []
    };

    private static __isMobile: boolean = false;

    public static get IsMobile(): boolean {
        return this.__isMobile;
    }

    public static set IsMobile(mobile: boolean) {
        this.__isMobile = mobile;
        if (mobile) {
            document.body.classList.add("mobile");
            document.body.classList.remove("desktop");
        } else {
            document.body.classList.remove("mobile");
            document.body.classList.add("desktop");
        }
    }

    public static __employee: EmployeeInfo = new EmployeeInfo();

    public static get Employee(): EmployeeInfo {
        if (!this.__employee) {
            let fn = sessionStorage.getItem("employee.fn");
            let gn = sessionStorage.getItem("employee.gn");
            let uid = sessionStorage.getItem("employee.uid");

            if (fn && gn && uid) {
                this.__employee = {
                    familyName: fn,
                    givenName: gn,
                    userId: uid
                };
            }
        }

        return this.__employee;
    }

    public static set Employee(eInfo: EmployeeInfo) {
        if (!eInfo) {
            eInfo = {
                userId: "?",
                givenName: "",
                familyName: ""
            };
        }

        this.__employee = eInfo;
        if (eInfo) {
            sessionStorage.setItem("employee.fn", eInfo.familyName);
            sessionStorage.setItem("employee.gn", eInfo.givenName);
            sessionStorage.setItem("employee.uid", eInfo.userId);
        }
    }

    private static __isIE: boolean = true;

    public static get IsIE(): boolean {
        return this.__isIE;
    }

    public static set IsIE(value: boolean) {
        this.__isIE = value;
        if (value) {
            document.body.classList.add("ie");
        } else {
            if (value) {
                document.body.classList.remove("ie");
            }
        }
    }

    public static TogglePatientList() {
        let $left = $(".router-left");
        let $right = $(".router-main");
        let $button = $(".toggle-menu-button-large-screen");

        $left.removeClass("override");
        $right.removeClass("override");
        $button.removeClass("override");
        if (!$left.is(":visible")) {
            $left.addClass("mobile-hidden");
            $left.show();
        }

        $left.toggleClass("mobile-hidden");
        if ($left.hasClass("mobile-hidden")) {
            $(".toggle-menu-button").animate({"left": "0", "margin-left": "0"}, 250);
        } else {
            $(".toggle-menu-button").animate({"left": "300px", "margin-left": "-48px"});
        }
    }

    /** toggles the display of the patient-list on Desktop browsers. See togglePatientList for Mobile-Version */
    public static TogglePatientListLG(hide?: boolean, hideAlsoButton?: boolean) {
        // if (App.runEmbedded) hide = true; // ha! You will never use this to display the list in embedded mode!

        let $left = $(".router-left");
        let $right = $(".router-main");
        let $button = $(".toggle-menu-button-large-screen");
        let $buttonSmall = $(".toggle-menu-button");

        if (typeof hide === "undefined") {
            //if (this.leftVisible) {
            $left.toggleClass("override");
            $right.toggleClass("override");
            $button.toggleClass("override");

            if (hideAlsoButton) {
                $buttonSmall.hide();
            }
        } else {
            if (hide === true) {
                $left.addClass("override");
                $right.addClass("override");
                $button.addClass("override");

                if (hideAlsoButton) {
                    $buttonSmall.hide();
                }
            } else {
                $left.removeClass("override");
                $right.removeClass("override");
                $button.removeClass("override");

                if (hideAlsoButton) {
                    $buttonSmall.show();
                }
            }
        }

        if ($left.hasClass("override")) {
            $("html").removeClass("menu-visible");
        } else {
            $("html").addClass("menu-visible");
        }

        setTimeout(() => {
            const event = document.createEvent('HTMLEvents');
            event.initEvent('resize', true, false);
            document.dispatchEvent(event);
        }, 250);
    }

    public static ClosePatientList() {
        if ($(".toggle-menu-button-large-screen").is(":visible")) return;
        this.TogglePatientList();
    }

    public static AppVersion: string;

    public static get Environment() {
        return environment;
    }

    public static Style: string = "default";

    public static get AllowAddPatients() {
        return this.Features.allowAddPatients;
    }

    public static AllowEditUserSettings: boolean = false;
    public static Language: string = "de";
    public static DateFormat: string = "DD.MM.YYYY";
    public static TimeFormat: string = "HH:mm";
    public static DateTimeFormat: string = "DD.MM.YYYY, HH:mm";
    public static SystemHeader: string = "http://hospital.com";
    public static EncounterDateFormat: string = "YYYY-MM-DDT00:01:00Z";

    public static Features = {
        "allowCasesHistorySearch": false,
        "automaticallyJumpFromCompletedAssessmentToAnalysis": true,
        "assessmentNeedsToHaveCompletedAnamnesis": true,
        "allowEditUserSettings": true,
        "woundAutomaticResponseCreation": true,
        "showFastPdfPrintButton": true,
        "applicationHeader": true,
        "processArrows": true,
        "bottomTabs": true,
        "draw": false,
        "showTargets": true,
        "riskOverview": [],
        "spiSpider": true,
        "hidePlaceholders": true,
        "allowAddPatients": false,
        "allowReleasePatient": false,
        "patientNameRestrictions": {
            "maxGivenNameLength": 30,
            "maxFamilyNameLength": 30
        },
        "autoUserCreation": {
            "enabled": false,
            "defaultPassword": "123456",
            "unlockAccount": true,
            "forcePasswordChange": false
        },
        "kurve": {
            "enabled": false,
            "url": ""
        },
        "cave" : {
            "enabled": false
        },
        "appHeader": {
            "displayAllergies": false,
            "displayDiagnosis": false,
            "displayBirthDate": false,
            "displayGender": false,
            "displayCaseId": false,
            "displayTreatmentDays": false,
            "allergiesColor": "rgb(255,113,113)",
            "displayLogo": true
        },
        "display_user_menu": true,
        "dedicatedPhotosPage": false,
        "enableRoomColors": true,
        "assumeLocationsWithoutStatusAs": "active",
        "encounterTypeChoice": {
            "enabled": false,
            "choices": [
                {
                    "id": "Sempa",
                    "title": "Sempa Encounter",
                    "analyzer": "Sempa",
                    "forms": {
                        "assessment": "CareITAssessmentSempaAkut",
                        "anamnesis": "CareITAnamnesisSempaAkut"
                    }
                }
            ]
        },
    };
}
